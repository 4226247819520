
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { ServerType } from "prometheus-synced-ui";
import { LITE_FEATURE, SocietyDetails, SocietyInfos } from "@/domain";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ISocietyService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class SocietiesTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get societyService() {
    return this.container.resolve<ISocietyService>(S.SOCIETY_SERVICE);
  }

  get headers() {
    return [
      {
        text: "Nom de la société",
        align: "center",
        sortable: true,
        value: "name",
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
        align: "center",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  get createComponent() {
    return () => import("@/components/Core/Create/SocietyCreate.vue");
  }

  get updateComponent() {
    return () => import("@/components/Core/Update/SocietyUpdate.vue");
  }

  get existingNames() {
    return _.map(this.societies, (s) => s.name);
  }

  loading: boolean = false;
  societies: SocietyInfos[] = [];

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.loading = true;
    try {
      this.societies = await this.societyService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(society: SocietyDetails) {
    this.societies.push(SocietyInfos.fromDetails(society));
  }

  update(society: SocietyDetails) {
    const index = _.findIndex(this.societies, (s) => s.id == society.id);
    if (index != -1) {
      this.societies.splice(index, 1, SocietyInfos.fromDetails(society));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const society = _.find(this.societies, (s) => s.id == id)!;
      const result = await this.societyService.update(id, {
        name: society.name,
        disabled: !society.disabled,
      });

      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
