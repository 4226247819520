
import { Component, Vue } from "vue-property-decorator";

import SocietiesTable from "@/components/Core/Table/SocietiesTable.vue";

@Component({
  components: {
    SocietiesTable,
  },
})
export default class Societies extends Vue {}
